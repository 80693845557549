import React from "react";
import Contents from "../Components/Contents";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "../css/styles.css";

const App = () => {
  return (
    <>
      <Header></Header>
      <Contents></Contents>
      <Footer></Footer>
    </>
  );
};

export default App;
